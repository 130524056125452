import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from 'components/shared/LazyImage'

import Image from 'types/image'

export type Props = {
  images: Image[]
  imagesHeight?: CSSProperties['height']
  options?: Swiper
}

const Wrapper = styled.div<Pick<Props, 'imagesHeight'>>`
  position: relative;

  .swiper-slide {
    height: ${({ imagesHeight }) => imagesHeight ?? 'auto'};
  }
`

const GalleryCarousel: React.FC<Props> = ({
  images,
  imagesHeight,
  options = {},
}) => {
  return (
    <Wrapper imagesHeight={imagesHeight}>
      <Swiper
        mousewheel={{ releaseOnEdges: true }}
        centerInsufficientSlides
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...options}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <LazyImage src={image.src} alt={image.alt || ''} fill />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default GalleryCarousel
