import React from 'react'
import styled from 'styled-components'

import { Heading, WithLine, Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import { Col, Row } from 'components/shared/Grid'
import LazyImage from 'components/shared/LazyImage'

import Image from 'types/image'

export type Collaboration = {
  title: string
  description: string
  image: Image
}

type Collaboration2Props = {
  collaborations: Collaboration[]
}

const Section = styled.section`
  position: relative;
  margin-top: 5rem;
`

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.media.xl.min} {
    padding: 0 2rem;

    ${Heading} {
      text-align: center;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  margin-top: 2.5rem;

  ${({ theme }) => theme.media.lg.min} {
    margin-top: 0;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

const Collaborations = styled.div`
  display: flex;
  flex-direction: column;
`

const CollaborationSingle = styled.div`
  margin-bottom: 4rem;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;

    :nth-child(odd) {
      ${TextWrapper} {
        order: 1;
      }
    }
  }
`

const Collaboration2Section: React.FC<Collaboration2Props> = ({
  collaborations,
}) => {
  return (
    <Section>
      <Container>
        <Collaborations>
          {collaborations.map((collaboration, index) => {
            return (
              <CollaborationSingle key={`collaboration-${collaboration.title}`}>
                <TextWrapper>
                  <Heading
                    as="h2"
                    dangerouslySetInnerHTML={{ __html: collaboration.title }}
                    align="center"
                    margin="1.5rem"
                    size={50}
                    sizeDiff={0.7}
                    weight={500}
                  />
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: collaboration.description,
                    }}
                    align="center"
                    // margin="2rem"
                    size={18}
                    weight={400}
                    line={1.6}
                    themecolor="darkgray"
                  />
                </TextWrapper>
                <ImageWrapper>
                  <LazyImage
                    src={collaboration.image.src}
                    alt={collaboration.image.alt!}
                  />
                </ImageWrapper>
              </CollaborationSingle>
            )
          })}
        </Collaborations>
      </Container>
    </Section>
  )
}

export default Collaboration2Section
