import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header, { HeaderSlide } from 'components/layout/Headers/WithSlides'
import { Props as GalleryCarouselProps } from 'components/layout/Carousels/GalleryCarousel'

import YoungSection from 'components/layout/Sections/Sport/Young'
import SponsorSection from 'components/layout/Sections/Sport/Sponsor'
import CollaborationSection from 'components/layout/Sections/Sport/Collaboration'
import Collaboration2Section, {
  Collaboration,
} from 'components/layout/Sections/Sport/Collaboration/Collaboration2'

import type { SportPageQuery } from 'types/graphql'
import type Image from 'types/image'

const IndexPage: React.FC<PageProps<SportPageQuery>> = ({ data }) => {
  const PAGE = data?.page?.pageSupportSport
  const PAGE_SEO = data?.page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const HEADER_SLIDES: HeaderSlide[] = useMemo(
    () =>
      PAGE.supportSportPageS1Slider?.map((slide) => ({
        image: {
          src: slide?.supportSportPageS1SliderElementImg?.localFile
            ?.childImageSharp?.gatsbyImageData!,
          alt: slide?.supportSportPageS1SliderElementImg?.altText!,
        },
        title: slide?.supportSportPageS1SliderElementText!,
      })) || [],
    [PAGE.supportSportPageS1Slider]
  )

  const YOUNG_IMAGES: Image[] = useMemo(
    () =>
      PAGE.supportSportPageS3List?.map((slide) => ({
        src: slide?.supportSportPageS3ListElement?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: slide?.supportSportPageS3ListElement?.altText!,
      })) || [],
    [PAGE.supportSportPageS3List]
  )

  const YOUNG_GALLERY: GalleryCarouselProps['images'] = useMemo(
    () =>
      PAGE.supportSportPageS4Slider?.map((slide) => ({
        src: slide?.supportSportPageS4SliderElementImg?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: slide?.supportSportPageS4SliderElementImg?.altText!,
      })) || [],
    [PAGE.supportSportPageS4Slider]
  )

  const COLLABORATIONS_2: Collaboration[] = useMemo(
    () =>
      PAGE.supportSportPageS7?.map((item) => ({
        title: item?.supportSportPageS7Title!,
        description: item?.supportSportPageS7Text!,
        image: {
          src: item?.supportSportPageS7Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: item?.supportSportPageS7Img?.altText!,
        },
      })) || [],
    []
  )

  const SPONSOR_IMAGES: GalleryCarouselProps['images'] = useMemo(
    () =>
      PAGE.supportSportPageS5Slider?.map((slide) => ({
        src: slide?.supportSportPageS5SliderElementImg?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: slide?.supportSportPageS5SliderElementImg?.altText!,
      })) || [],
    [PAGE.supportSportPageS5Slider]
  )

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Wspieramy sport' }]} />
      <Header
        slides={HEADER_SLIDES}
        scrollText="Zjedź niżej"
        scrollElement="#main"
      />
      <main id="main">
        <CollaborationSection
          title={PAGE.supportSportPageS2Title}
          description={PAGE.supportSportPageS2Description}
          background={{
            src: PAGE?.supportSportPageS2Background?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE?.supportSportPageS2Background?.altText!,
          }}
          image={{
            src: PAGE?.supportSportPageS2Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE?.supportSportPageS2Img?.altText!,
          }}
          logo={{
            src: PAGE?.supportSportPageS2Logo?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE?.supportSportPageS2Logo?.altText!,
          }}
        />
        <YoungSection
          title={PAGE.supportSportPageS3Title}
          description={PAGE.supportSportPageS3Description}
          contentImages={YOUNG_IMAGES}
          gallery={YOUNG_GALLERY}
        />
        <Collaboration2Section collaborations={COLLABORATIONS_2} />
        <SponsorSection
          title={PAGE.supportSportPageS5Title}
          subtitle={PAGE.supportSportPageS5Description}
          images={SPONSOR_IMAGES}
        />
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query SportPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/wspieramy-sport|support-sport/" }
    ) {
      seo {
        ...WpSEO
      }
      pageSupportSport {
        supportSportPageS1Slider {
          supportSportPageS1SliderElementText
          supportSportPageS1SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }

        supportSportPageS2Description
        supportSportPageS2Title
        supportSportPageS2Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 960
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        supportSportPageS2Logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 960
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        supportSportPageS2Background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        supportSportPageS3Description
        supportSportPageS3Title
        supportSportPageS3List {
          supportSportPageS3ListElement {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 960
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        supportSportPageS4Slider {
          supportSportPageS4SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        supportSportPageS5Description
        supportSportPageS5Title
        supportSportPageS5Slider {
          supportSportPageS5SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        supportSportPageS7 {
          supportSportPageS7Title
          supportSportPageS7Text
          supportSportPageS7Img {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
