import React from 'react'
import styled from 'styled-components'

import { breakpoints } from 'styles/theme'

import GalleryCarousel, {
  Props as GalleryCarouselProps,
} from 'components/layout/Carousels/GalleryCarousel'
import { Heading, Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import { SwiperOptions } from 'swiper'

type Props = {
  title?: string | null
  subtitle?: string | null
  images: GalleryCarouselProps['images']
}

const Section = styled.section`
  padding: 3rem 0 4rem;
  ${({ theme }) => theme.media.lg.max} {
    padding: 3rem 0;
  }

  ${({ theme }) => theme.media.sm.max} {
    padding: 3.5rem 0;
    ${Text} {
      font-size: 18px;
    }
  }
`

const GalleryWrapper = styled.div`
  .swiper {
    ${({ theme }) => theme.media.sm.max} {
      padding-left: ${({ theme }) => theme.container.padding};
      padding-right: ${({ theme }) => theme.container.padding};
    }
    .swiper-slide {
      width: 80vw;
      height: 50vw;
      max-width: 560px;
      min-height: 360px;
      max-height: 800px;
    }
  }
`

const galleryOptions: SwiperOptions = {
  spaceBetween: 10,
  loop: true,
  slidesPerView: 'auto',
  grabCursor: true,
  centeredSlides: true,
  breakpoints: {
    [breakpoints.md]: {
      spaceBetween: 20,
    },
    [breakpoints.lg]: {
      spaceBetween: 30,
    },
  },
}

const SponsorSection: React.FC<Props> = ({ title, subtitle, images }) => {
  return (
    <Section>
      <Container>
        {title && (
          <Heading
            as="h2"
            dangerouslySetInnerHTML={{ __html: title }}
            align="center"
            margin="0.5rem"
            size={50}
            sizeDiff={0.7}
            weight={500}
          />
        )}
        {subtitle && (
          <Text
            as="div"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            align="center"
            margin="2rem"
            size={21}
            weight={400}
            themecolor="darkgray"
          />
        )}
      </Container>
      {images.length > 0 && (
        <GalleryWrapper>
          <GalleryCarousel images={images} options={galleryOptions} />
        </GalleryWrapper>
      )}
    </Section>
  )
}

export default SponsorSection
