import React from 'react'
import styled from 'styled-components'

import { Heading, WithLine, Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import { Col, Row } from 'components/shared/Grid'
import LazyImage from 'components/shared/LazyImage'

import Image from 'types/image'

type Props = {
  title?: string | null
  description?: string | null
  background: Image
  image: Image
  logo: Image
}

const Section = styled.section`
  position: relative;
  padding: 9rem 0;
  margin-top: 3rem;

  ${({ theme }) => theme.media.xl.max} {
    padding: 2rem 0 6rem;
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 2rem 0 4rem;
  }
  ${({ theme }) => theme.media.sm.max} {
    padding: 0 0 2rem;
    margin-bottom: 3rem;
    ${Text} {
      font-size: 1rem;
    }
  }
`

const ImagesWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: stretch;

  ${({ theme }) => theme.media.xxl.min} {
    min-height: 400px;
  }

  ${({ theme }) => theme.media.sm.min} {
    min-height: 350px;
  }

  ${({ theme }) => theme.media.lg.max} {
    margin-top: 20vw;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 40%;
  right: 0;
  bottom: 0;

  ${({ theme }) => theme.media.lg.max} {
    left: 0;
    top: inherit;
  }
`

const BackgroundImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
`

const LogoWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  transform: translate3d(-50%, 0, 0);

  height: 100%;

  ${({ theme }) => theme.media.lg.min} {
    left: 0;
  }
  ${({ theme }) => theme.media.lg.max} {
    right: 0;
  }
`

const LogoImage = styled(LazyImage)`
  height: 100%;
`

const CollaborationSection: React.FC<Props> = ({
  title,
  description,
  background,
  image,
  logo,
}) => {
  return (
    <Section>
      <BackgroundWrapper>
        <BackgroundImage
          src={background.src}
          alt={background.alt || ''}
          objectFit="cover"
        />
      </BackgroundWrapper>
      <Container slim>
        <Row alignItems="center">
          <Col xs={24} xxl={8} xl={9} lg={9}>
            {title && (
              <WithLine offset>
                <Heading
                  as="h2"
                  dangerouslySetInnerHTML={{ __html: title }}
                  margin="0.5rem"
                  size={50}
                  sizeDiff={0.7}
                  weight={500}
                />
              </WithLine>
            )}
            {description && (
              <Text
                as="article"
                dangerouslySetInnerHTML={{ __html: description }}
                size={18}
                weight={300}
              />
            )}
          </Col>
          <Col xs={17} xxl={12} xl={11} offset-xl={4} lg={10} offset-lg={5}>
            <ImagesWrapper>
              <LogoWrapper>
                <LogoImage
                  src={logo.src}
                  alt={logo.alt || ''}
                  objectFit="contain"
                />
              </LogoWrapper>

              <LazyImage
                src={image.src}
                alt={image.alt || ''}
                objectFit="cover"
              />
            </ImagesWrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default CollaborationSection
