import React from 'react'
import styled, { css, keyframes, CSSProperties } from 'styled-components'
import { SwiperOptions } from 'swiper'

import { breakpoints } from 'styles/theme'
import useBreakpoint from 'hooks/useBreakpoint'

import GalleryCarousel, {
  Props as GalleryCarouselProps,
} from 'components/layout/Carousels/GalleryCarousel'
import { Heading, Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import { Col, Row } from 'components/shared/Grid'
import Image from 'components/shared/Image'
import LazyImage from 'components/shared/LazyImage'

import PitchCenterImage from 'assets/images/pitch-center.svg'
import PitchBottomImage from 'assets/images/pitch-bottom.svg'
import ballImg from 'assets/images/ball.png'

type Props = {
  title?: string | null
  description?: string | null
  contentImages: GalleryCarouselProps['images']
  gallery: GalleryCarouselProps['images']
}

const ContentImageBackgroundOffset: CSSProperties['top'] = '7px'
const ClipPathsHeight: CSSProperties['height'] = '200px'

const Section = styled.section`
  margin-top: -8rem;
  padding: 3rem 0 4rem;
  ${({ theme }) => theme.media.lg.max} {
    padding: 3rem 0;
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 2rem 0;
    margin-top: 0;
  }
`

const Wrapper = styled.div`
  position: relative;

  padding-bottom: 6rem;
  background: ${({ theme }) => theme.colors.dark};
  z-index: 3;
`

const StyledRow = styled(Row)`
  ${({ theme }) => theme.media.md.max} {
    flex-direction: column-reverse;
  }
`

const ClipPaths = styled.div`
  position: relative;

  height: ${ClipPathsHeight};
  width: 100%;
  margin-bottom: -2px;

  ${({ theme }) => theme.media.md.max} {
    height: calc(${ClipPathsHeight} / 2);
  }
`

const ClipPath1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  background: ${({ theme }) => theme.colors.dark};

  clip-path: polygon(25% 60%, 100% 20%, 100% 100%, 0 100%, 0 100%);
`

const ClipPath2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  background: ${({ theme }) => theme.colors.primary};

  clip-path: polygon(25% 50%, 100% 0, 100% 100%, 0 100%, 0 95%);
`

const PitchCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;

  width: 80%;

  transform: translate3d(-50%, -50%, 0);

  ${({ theme }) => theme.media.xxl.max} {
    width: 100%;
  }
`

const PitchBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 35%;

  transform: translate3d(-50%, 0, 0);

  ${({ theme }) => theme.media.xxl.max} {
    width: 50%;
  }
  ${({ theme }) => theme.media.md.max} {
    width: 40%;
  }
  ${({ theme }) => theme.media.sm.max} {
    width: 60%;
  }
`

const GalleryWrapper = styled.div`
  margin-top: -6rem;
  position: relative;
  z-index: 5;

  .swiper {
    ${({ theme }) => theme.media.md.max} {
      padding-left: ${({ theme }) => theme.container.padding};
      padding-right: ${({ theme }) => theme.container.padding};
    }
  }
`

const ImagesCol = styled(Col)`
  position: relative;

  ${({ theme }) => theme.media.md.max} {
    min-height: 450px;
  }
`

const Content = styled.div`
  padding-top: 8rem;
  padding-bottom: 10rem;
  ${({ theme }) => theme.media.md.max} {
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
  ${({ theme }) => theme.media.sm.max} {
    padding-top: 10rem;
    & > ${Text} {
      font-size: 1rem;
    }
  }
  ${({ theme }) => theme.media.xs.max} {
    padding-top: 6rem;
  }
`

const ImagesContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const ContentImage = styled.div<{ index: number }>`
  position: absolute;
  z-index: 2;

  ${({ index }) =>
    index > 0 &&
    css`
      &::before {
        content: '';

        position: absolute;
        top: ${ContentImageBackgroundOffset};
        left: ${ContentImageBackgroundOffset};

        width: 100%;
        height: 100%;

        clip-path: polygon(
          0 0,
          100% 0,
          100% calc(100% - 2 * ${ContentImageBackgroundOffset}),
          calc(100% - 2 * ${ContentImageBackgroundOffset}) 100%,
          0 100%
        );

        background: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ index }) =>
    index === 0 &&
    css`
      top: calc(-1 * ${ClipPathsHeight});
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      ${({ theme }) => theme.media.md.max} {
        display: none;
      }
    `}

  ${({ index }) =>
    index === 1 &&
    css`
      top: 15%;
      left: 0;
      ${({ theme }) => theme.media.md.max} {
        top: 0;
      }
    `}

    ${({ index }) =>
    index === 2 &&
    css`
      top: 30%;
      right: 5%;
    `}

    ${({ index }) =>
    index === 3 &&
    css`
      bottom: 10%;
      left: 5%;
    `}
`

const FirstContentImage = styled.div`
  display: none;
  ${({ theme }) => theme.media.sm.max} {
    display: block;
    position: absolute;
    width: calc(100% - 2 * ${({ theme }) => theme.container.padding});
    top: calc(${ClipPathsHeight} / -2);
    left: 50%;
    transform: translate3d(-50%, -25%, 0);
  }
`

const roll = keyframes`
  0% {
    transform: translate3d(-200px, -400px, 0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  12% {
    transform: translate3d(200px, -250px, 0);
  }
  25% {
    transform: translate3d(-200px, -100px, 0);
  }
  49% {
    opacity: 1;
  }
  50% {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }
  51% {
    transform: translate3d(200px, -400px, 0);
    opacity: 0;
  }
  56% {
    opacity: 1;
  }
  63% {
    transform: translate3d(-200px, -250px, 0);
  }
  76% {
    transform: translate3d(200px, -100px, 0);
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Ball = styled.div`
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 10;
  animation: ${roll} 4.5s cubic-bezier(0.3, 0.4, 0.45, 0.5) infinite;
  opacity: 0;
  animation-delay: 1s;
  pointer-events: none;
`

const BallIcon = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  background: url(${ballImg}) no-repeat center;
  background-size: 100%;
  animation: ${spin} 0.6s linear infinite;
`

const YoungSection: React.FC<Props> = ({
  title,
  description,
  contentImages,
  gallery,
}) => {
  const { md } = useBreakpoint()
  const galleryOptions: SwiperOptions = {
    spaceBetween: 30,
    grabCursor: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    breakpoints: {
      [breakpoints.md]: {
        slidesPerView: 3,
      },
      [breakpoints.xxl]: {
        slidesPerView: 4,
      },
    },
  }

  return (
    <Section>
      <ClipPaths>
        <ClipPath1 />
        <ClipPath2 />
      </ClipPaths>
      <Wrapper>
        {contentImages.length > 0 && (
          <FirstContentImage>
            <LazyImage
              src={contentImages[0].src}
              alt={contentImages[0].alt || ''}
              objectFit="contain"
            />
          </FirstContentImage>
        )}
        <Container slim>
          <StyledRow alignItems="stretch">
            <ImagesCol xs={24} md={13}>
              <PitchCenter>
                <Image src={PitchCenterImage} alt="" objectFit="contain" />
              </PitchCenter>
              <PitchBottom>
                <Ball>
                  <BallIcon />
                </Ball>
                <Image src={PitchBottomImage} alt="" objectFit="contain" />
              </PitchBottom>
              {contentImages.length > 0 && (
                <ImagesContent>
                  {contentImages.map((image, index) => (
                    <ContentImage index={index}>
                      <LazyImage
                        src={image.src}
                        alt={image.alt || ''}
                        // eslint-disable-next-line no-nested-ternary
                        height={index < 3 ? (md ? 200 : 125) : md ? 100 : 100}
                        objectFit="contain"
                      />
                    </ContentImage>
                  ))}
                </ImagesContent>
              )}
            </ImagesCol>
            <Col xs={24} xxl={8} xl={9} md={10} offset-md={1}>
              <Content>
                {title && (
                  <Heading
                    as="h2"
                    dangerouslySetInnerHTML={{ __html: title }}
                    themecolor="white"
                    margin="0.5rem"
                    size={50}
                    sizeDiff={0.7}
                    weight={500}
                  />
                )}
                {description && (
                  <Text
                    as="article"
                    dangerouslySetInnerHTML={{ __html: description }}
                    size={18}
                    weight={300}
                    themecolor="white"
                  />
                )}
              </Content>
            </Col>
          </StyledRow>
        </Container>
      </Wrapper>
      {gallery.length > 0 && (
        <GalleryWrapper>
          <GalleryCarousel images={gallery} options={galleryOptions} />
        </GalleryWrapper>
      )}
    </Section>
  )
}

export default YoungSection
